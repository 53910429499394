$(function(){
    $('.nav-wrapper .control-left').on('click', function(){
        var list = $(this).parent().find('.nav');
        list.animate({
            scrollLeft:"-=200px"
        }, "slow");
    });
    $('.nav-wrapper .control-right').on('click', function(){
        var list = $(this).parent().find('.nav');
        list.animate({
            scrollLeft:"+=200px"
        }, "slow");
    });
    $('.tab-collapse').find('.nav-item').on('click', function(){
        var list = $(this).parent();
        var listWidth = list.width();
        var itemLeft = $(this).position().left;
        var width = $(this).width();
        var a = (listWidth - width) / 2;
        var difference = a - itemLeft + 54;
        list.animate({
          scrollLeft:"-=" + difference
        }, "slow");
    });
    tabCollapse();
});

$(window).on('resize', function(){
    tabCollapse();
});

function tabCollapse() {
    $('.tab-collapse').each(function(){
        var element = $(this);

        // Check if last tab is visible
        var last = element.find('.nav-tabs').children().last();
        var right = last.position().left + last.width();
        
        // Toggle collapse based on visibility of last tab
        if(right > element.width()){
            element.addClass('collapsed');
        } else {
            element.removeClass('collapsed');
        };
    })
};


function handleDatePickers(selector) {
    $(selector).each(function () {
        $(this).datepicker({
            uiLibrary: 'bootstrap4',
            showOnFocus: true,
            iconsLibrary: 'fontawesome',
            format: 'yyyy-mm-dd',
            icons: {
                rightIcon: '<i class="far fa-calendar-alt"></i>'
            }
        });
    });
}
